<template>
  <div>
    <b-row>
      <b-col
        lg="3"
        sm="12"
      >
        <b-card title="Content Count">
          {{ content_count }}
        </b-card>
      </b-col>
      <b-col
        v-if="audio_visuals.length"
        lg="4"
        sm="12"
      >
        <b-card title="Audio Visuals/Dramatic Works">
          <div class="d-flex justify-content-between">
            <span>{{ audio_visuals_count }}</span>
            <b-button
              v-if="audio_visuals.length"
              size="sm"
              :to="{ name: 'member-audio-visuals' }"
            >View All</b-button>
          </div>
        </b-card>
      </b-col>
      <!-- <b-col
        v-if="sound_recordings.length"
        lg="4"
        sm="12"
      >
        <b-card title="Sound Recording Rights">
          <div class="d-flex justify-content-between">
            <span>{{ sound_recordings_count }}</span>
            <b-button
              v-if="sound_recordings.length"
              size="sm"
              :to="{ name: 'member-sound-records' }"
            >View All</b-button>
          </div>
        </b-card>
      </b-col> -->
      <b-col
        v-if="musical_works.length"
        lg="4"
        sm="12"
      >
        <b-card title="Music Rights">
          <div class="d-flex justify-content-between">
            <span>{{ musical_works_count }}</span>
            <b-button
              v-if="musical_works.length"
              size="sm"
              :to="{ name: 'member-musical-works' }"
            >View All</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      class="mb-2"
      :to="{ name: 'register-content-upload' }"
    >
      Add Content
    </b-button>
    <!-- <b-card>
      <b-row>
        <b-col
          lg="6"
          sm="12"
        >
          <h3>My Sound Recording Rights</h3>
        </b-col>
        <b-col
          lg="6"
          sm="12"
        >
          <b-button
            v-if="sound_recordings.length"
            size="sm"
            class="ml-2"
            :to="{ name: 'member-sound-records' }"
          >View All Sound Recording Rights</b-button>
        </b-col>
        <b-col
          lg="12"
          sm="12"
        >
          <b-row v-if="sound_recordings.length">
            <b-col lg="4">
              <strong>Approved: </strong>{{ sound_recordings.filter(recording => recording.status === 'Approved').length }}
            </b-col>
            <b-col lg="4">
              <strong>Pending: </strong>{{ sound_recordings.filter(recording => recording.status === 'Pending').length }}
            </b-col>
            <b-col lg="4">
              <strong>Denied:</strong> {{ sound_recordings.filter(recording => recording.status === 'Denied').length }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <p
        v-if="!sound_recordings.length"
        class="text-align-center"
      >
        No Sound Recording Rights
      </p>
      <b-table
        v-else
        responsive
        :items="sound_recordings"
        :fields="['title', 'status', 'uploaded_on', 'actions']"
        class="font-weight-bolder"
      >
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`${resolveContentVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(uploaded_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.created_at).format("dddd, MMMM Do YYYY") }}
          </b-card-text>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="data.item.status !== 'Approved'"
            class="text-nowrap"
          >
            <b-button
              size="sm"
              variant="secondary"
              :to="{ name: 'content-edit', params: { id: data.item.id } }"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card> -->

    <b-card>
      <b-row>
        <b-col
          lg="6"
          sm="12"
        >
          <h3>My Audio Visuals/Dramatics Works</h3>
        </b-col>
        <b-col
          lg="6"
          sm="12"
        >
          <b-button
            v-if="audio_visuals.length"
            size="sm"
            class="ml-2"
            :to="{ name: 'member-audio-visuals' }"
          >View All Audio Visuals</b-button>
        </b-col>
        <b-col
          lg="12"
          sm="12"
        >
          <b-row v-if="audio_visuals.length">
            <b-col cols="4">
              <strong>Aprroved: </strong>{{ audio_visuals.filter(recording => recording.status === 'Approved').length }}
            </b-col>
            <b-col cols="4">
              <strong>Pending: </strong>{{ audio_visuals.filter(recording => recording.status === 'Pending').length }}
            </b-col>
            <b-col cols="4">
              <strong>Denied:</strong> {{ audio_visuals.filter(recording => recording.status === 'Denied').length }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <p
        v-if="!audio_visuals.length"
        class="text-align-center"
      >
        No Audio Visuals/Dramatic Works Uploaded
      </p>
      <!-- Licenses: Table -->
      <b-table
        v-else
        responsive
        :items="audio_visuals"
        :fields="['title', 'status', 'uploaded_on', 'actions']"
        class="font-weight-bolder"
      >
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`${resolveContentVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(uploaded_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.created_at).format("dddd, MMMM Do YYYY") }}
          </b-card-text>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="data.item.status !== 'Approved'"
            class="text-nowrap"
          >
            <b-button
              size="sm"
              variant="secondary"
              :to="{ name: 'content-edit', params: { id: data.item.id } }"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-card>
      <b-row>
        <b-col
          lg="6"
          sm="12"
        >
          <h3>My Music Rights</h3>
        </b-col>
        <b-col
          lg="6"
          sm="12"
        >
          <b-button
            v-if="musical_works.length"
            size="sm"
            class="ml-2"
            :to="{ name: 'member-musical-works' }"
          >View All Music Rights</b-button>
        </b-col>
        <b-col
          lg="12"
          sm="12"
        >
          <b-row v-if="musical_works.length">
            <b-col lg="4">
              <strong>Aprroved: </strong>{{ musical_works.filter(recording => recording.status === 'Approved').length }}
            </b-col>
            <b-col lg="4">
              <strong>Pending: </strong>{{ musical_works.filter(recording => recording.status === 'Pending').length }}
            </b-col>
            <b-col lg="4">
              <strong>Denied:</strong> {{ musical_works.filter(recording => recording.status === 'Denied').length }}
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <p
        v-if="!musical_works.length"
        class="text-align-center"
      >
        No Music Rights Uploaded
      </p>
      <!-- Licenses: Table -->
      <b-table
        v-else
        responsive
        :items="musical_works"
        :fields="['title', 'status', 'uploaded_on', 'actions']"
        class="font-weight-bolder"
      >
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`${resolveContentVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(uploaded_on)="data">
          <b-card-text class="text-nowrap">
            {{ moment(data.item.created_at).format("dddd, MMMM Do YYYY") }}
          </b-card-text>
        </template>
        <template #cell(actions)="data">
          <div
            v-if="data.item.status !== 'Approved'"
            class="text-nowrap"
          >
            <b-button
              size="sm"
              variant="secondary"
              :to="{ name: 'content-edit', params: { id: data.item.id } }"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BCardText,
  VBToggle,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardText,
    BBadge,
  },
  data() {
    return {
      content_count: 0,
      sound_recordings: [],
      audio_visuals: [],
      dramatic_works: [],
      musical_works: [],
      audio_visuals_count: 0,
      sound_recordings_count: 0,
      musical_works_count: 0,
    }
  },
  mounted() {
    this.$http.get('/membership/member/content').then(response => {
      this.content_count = response.data.content_count
      this.audio_visuals = response.data.audio_visuals.reverse().splice(0, 6)
      this.audio_visuals_count = response.data.audio_visuals_count
      this.sound_recordings = response.data.sound_recordings.reverse().splice(0, 6)
      this.musical_works_count = response.data.sound_recordings_count
    })
  },

  methods: {
    resolveContentVariant(status) {
      if (status === 'Approved') return 'success'
      if (status === 'Pending') return 'secondary'
      if (status === 'Denied') return 'danger'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-header-btn {
  margin-right: 2px;
}
@media only screen and (max-width: 992px) {
  .table-header-btn {
    margin-top: 3px;
    margin-right: 0;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
